<script>
    // -- IMPORTS

    import { onMount } from 'svelte';
    import { Router, Route } from 'svelte-routing';
    import HomePage from './lib/page/HomePage.svelte';

    import { defineDualTag, defineLineTag, defineTag, getLocationFromIpAddress, setLanguageTag } from 'senselogic-gist';
    import { fetchData } from './lib/base';
    import { languageArrayStore } from './lib/store/languageArrayStore';
    import { languageTagStore } from './lib/store/languageTagStore';
    import { founderArrayStore, managerArrayStore, teamArrayStore } from './lib/store/memberStore';
    import { textStore } from './lib/store/textStore';
    import { languageByCodeMapStore } from './lib/store/languageByCodeMapStore';
    import { ensureLanguageTagInPath, currentPathname } from '$lib/router';
    import Header from './lib/component/Layout/Header.svelte';
    import CallToActionSection from './lib/component/Layout/CallToActionSection.svelte';
    import Footer from './lib/component/Layout/Footer.svelte';
    import CookieConsent from './lib/component/Layout/CookieConsent.svelte';
    import Digitalization from './lib/page/Digitalization.svelte';
    import Networking from './lib/page/Networking.svelte';
    import Hubs from './lib/page/hubs.svelte';
    import Hub from './lib/page/hub.svelte';
    import News from './lib/page/News.svelte';
    import Membership from './lib/page/Membership.svelte';
    import BecomeMember from './lib/page/BecomeMember.svelte';
    import PrivacyPolicy from './lib/page/PrivacyPolicy.svelte';
    import TermsOfService from './lib/page/TermsOfService.svelte';
    import Login from './lib/page/Login.svelte';
    import { user } from './lib/store/userStore';
    import ToastContainer from './lib/component/Toast/ToastContainer.svelte';
    import { userLocationStore } from './lib/store/userLocationStore';
    import FullLoading from './lib/component/Layout/FullLoading.svelte';
    import Team from './lib/page/Team.svelte';

    // -- DEFINE TAGS

    defineLineTag( '! ', '<div class="paragraph title-1">', '</div>' );
    defineLineTag( '!! ', '<div class="paragraph title-2">', '</div>' );
    defineLineTag( '!!! ', '<div class="paragraph title-3">', '</div>' );
    defineLineTag( '!!!! ', '<div class="paragraph title-4">', '</div>' );
    defineLineTag( '- ', '<div class="paragraph dash-1">', '</div>' );
    defineLineTag( '  - ', '<div class="paragraph dash-2">', '</div>' );
    defineLineTag( '    - ', '<div class="paragraph dash-3">', '</div>' );
    defineLineTag( '      - ', '<div class="paragraph dash-4">', '</div>' );
    defineLineTag( '* ', '<div class="paragraph bullet-1">', '</div>' );
    defineLineTag( '  * ', '<div class="paragraph bullet-2">', '</div>' );
    defineLineTag( '    * ', '<div class="paragraph bullet-3">', '</div>' );
    defineLineTag( '      * ', '<div class="paragraph bullet-4">', '</div>' );
    defineLineTag( '', '<div class="paragraph">', '</div>' );

    defineDualTag( '**', '<b>', '</b>' );
    defineDualTag( '%%', '<i>', '</i>' );
    defineDualTag( '__', '<u>', '</u>' );
    defineDualTag( ',,', '<sub>', '</sub>' );
    defineDualTag( '^^', '<sup>', '</sup>' );

    defineTag( '~', '&nbsp;' );
    defineTag( '¦', '<wbr/>' );
    defineTag( '§', '<br/>' );
    defineTag( '¶', '<br class="linebreak"/>' );
    defineTag( '((', '<a class="link" href="' );
    defineTag( ')(', '" target="_blank">' );
    defineTag( '))', '</a>' );

    // -- VARIABLES

    let isLoading = true;

    let layout = {};

    export let url = "";

    let isMounted = false;

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true
            let userIp = await getUserIp();

            let data = 
                await fetchData(
                    `/api/layout/${ userIp }`,
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                languageTag: 'en',
                                user: 'Js'
                            }
                        ),
                        credentials: 'include',
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            layout = data.layout

            if ( data.layout.textMap )
            {
                textStore.set(
                    data.layout.textMap
                );
            }
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
            isMounted = true;
        }
    }

    async function getUserIp(
        )
    {
        let response = await fetch( 'https://api.ipify.org?format=json' );
        
        let responseToJsonPromise = await response.json()

        return responseToJsonPromise.ip
    }

    // -- STATEMENTS

    $:
    {
        layout
        layout.languageTag;
        $languageByCodeMapStore = layout.languageByCodeMap
        $languageTagStore = layout.languageTag;
        $user = layout.user;
        $userLocationStore = layout.userLocation;
        setLanguageTag( layout.languageTag );
        teamArrayStore.set( layout.teamMemberArray );
        managerArrayStore.set( layout.managerMemberArray );
        founderArrayStore.set( layout.founderMemberArray );
        languageArrayStore.set( layout.languageArray );
    }

    // ~~

    onMount(
        async () =>
        {
            await loadData();
            ensureLanguageTagInPath( window.location, $languageTagStore );
        }
    )

    $: if ( isMounted )
    {
        $currentPathname, ensureLanguageTagInPath( window.location, $languageTagStore );
    }

</script>

<style lang="stylus">
    // -- IMPORTS

    @import "./app.styl";

    nav {
        width: 100%;
        max-width: 100vw;
    }

    +media( desktop )
    {
        main
        {
            background: URL( 'https://eguzzwfhunynwpfcdxdz.supabase.co/storage/v1/object/public/global/image/background/background.svg' ), var(--darkGreyColor);
            background-position: center;
            background-size: cover;
        }
    }
</style>

<main>
    { #if isLoading }
        <FullLoading />
    { :else }
        <Router { url }>
            <nav style="display: unset;">
                <Header />
            </nav>
            <div class='slot-container'>
                <Route path='/:languageTag/*'>
                    <Router basepath=':/languageTag'>
                        <Route>
                            <HomePage/>
                        </Route>
                        <Route path="/team">
                            <Team />
                        </Route>
                        <Route path="/digitalization">
                            <Digitalization />
                        </Route>
                        <Route path="/networking">
                            <Networking/>
                        </Route>
                        <Route path="/hubs">
                            <Hubs />
                        </Route>
                        <Route path="/hub/:slug" let:params>
                            <Hub slug={ params.slug } />
                        </Route>
                        <Route path="/membership">
                            <Membership />
                        </Route>
                        <Route path="/news">
                            <News />
                        </Route>
                        <Route path="/become-member">
                            <BecomeMember/>
                        </Route>
                        <Route path="/services">
                            <HomePage/>
                        </Route>
                        <Route path="/login">
                            <Login/>
                        </Route>
                        <Route path="/privacy-policy">
                            <PrivacyPolicy />
                        </Route>
                        <Route path="/terms-of-service">
                            <TermsOfService/>
                        </Route>
                    </Router>
                </Route>
                <CallToActionSection />
                <Footer />
                <ToastContainer />
            </div>
            <CookieConsent />
        </Router>
    { /if }
</main>
