// -- IMPORTS

import { getLocalizedText, getLocalizedTextBySlug, getProcessedMultilineText } from 'senselogic-gist';
import { Capacitor } from '@capacitor/core';
import { get } from 'svelte/store';
import { languageTagStore } from './store/languageTagStore';
import { derived, writable } from 'svelte/store'

// -- CONSTANTS

export let platform = Capacitor.getPlatform();
export let hostUrl = ( platform === 'android' ? 'https://bamhub.com/' : '' );
export let websiteUrl = 'https://bamhub.com';

// -- FUNCTIONS

export async function fetchData(
    route,
    options = { method: 'GET' }
    )
{
    let url = hostUrl + route;

    try
    {
        let response = await fetch( url, options );

        if ( !response.ok )
        {
            throw new Error( 'Network response was not ok: ' + response.status );
        }

        return await response.json();
    }
    catch ( error )
    {
        console.error( 'There was a problem with your fetch operation:', error );

        throw error;
    }
}

// ~~

export function clickOutside(
    node
    )
{
    function handleClickEvent(
        event
        )
    {
        if ( node && !node.contains( event.target ) && !event.defaultPrevented )
        {
            node.dispatchEvent(
                new CustomEvent( 'clickOutside', node )
                );
        }
    }

    document.addEventListener( 'click', handleClickEvent, true );

    return (
        {
            destroy()
            {
                document.removeEventListener( 'click', handleClickEvent, true );
            }
        }
        );
}

// ~~

export function encodeFileToBase64Url(
    file
    )
{
    return new Promise(
        ( resolve, reject ) =>
        {
            let reader = new FileReader();
            reader.onload = () => resolve( reader.result.toString() );
            reader.onerror = error => reject( error);
            reader.readAsDataURL( file );
        }
        );
}

// ~~

export function decodeBase64UrlToFile(
    base64Url,
    filename
    )
{
    return new Promise(
        ( resolve, reject ) =>
        {
            let base64EncodedData = base64Url.split( ';base64,' ).pop();

            let byteCharacters = atob( base64EncodedData );
            let byteNumbers = new Array( byteCharacters.length );

            for ( let i = 0; i < byteCharacters.length; i++ )
            {
                byteNumbers[ i ] = byteCharacters.charCodeAt( i );
            }

            let byteArray = new Uint8Array( byteNumbers );

            let fileBlob = new Blob( [ byteArray ], { type: 'application/octet-stream' } );

            let file = new File( [ fileBlob ], filename, { type: 'application/octet-stream' } );

            resolve( file );
        }
        );
}

// ~~

export function getProcessedMultilineTranslatedText( text, variableMap = {} )
{
    let processedText = getProcessedMultilineText( getLocalizedText( text, variableMap, get( languageTagStore ) ).replace(/\n(?!\\n)/g, '\n') );

    return processedText;
}

// ~~

export function getProcessedMultilineTranslatedTextBySlug( text, variableMap = {} )
{
    let processedText = getProcessedMultilineText( getLocalizedTextBySlug( text, variableMap, get( languageTagStore ) ).replace(/\n(?!\\n)/g, '\n') );

    return processedText;
}

// ~~

export function sortNumberAscending(
    numA,
    numB
    )
{
    return numA - numB;
}

// ~~

export function sortNumberDescending(
    numA,
    numB
    )
{
    return numB - numA;
}

// ~~

export function getFormattedCurrencyValue(
    value,
    currency
    )
{
    let valueAsString = value.toString();
    let fixedCurrency = valueAsString.includes( currency ) ? currency : 'eur';

    let valueByCurrency = getLocalizedText( valueAsString, fixedCurrency, fixedCurrency );

    let valueAsNumber = parseInt( valueByCurrency );

    return formatPrice( valueAsNumber, fixedCurrency );
}

// ~~

export function formatPrice(
    value,
    currency
    )
{
    if( currency === 'brl' )
    {
        return new Intl.NumberFormat(
            'pt-BR',
            {
                style: 'currency',
                currency: 'BRL' }
            )
            .format( value );
    }
    else if( currency === 'usd' )
    {
        return new Intl.NumberFormat(
            'en-US',
            {
                style: 'currency',
                currency: 'USD' }
            )
            .format( value );
    }
    else
    {
        return new Intl.NumberFormat(
           'de-DE',
           {
               style: 'currency',
               currency: 'EUR' }
           )
           .format( value );
    }
}

// ~~

export function isObjectEmpty(
    object
    )
{
    for ( var key in object )
    {
        if ( object.hasOwnProperty( key ) && object[ key ].trim() !== '' )
        {
            return false;
        }
    }

    return true;
}

// ~~

export function createUrlStore( ssrUrl ) {
    if ( typeof window === 'undefined' ) {
    let { subscribe } = writable( ssrUrl )
      return { subscribe }
    }

    let href = writable( window.location.href )

    let originalPushState = history.pushState
    let originalReplaceState = history.replaceState

    let updateHref = () => href.set( window.location.href )

    history.pushState =
        function () {
            originalPushState.apply( this, arguments )
            updateHref()
        }

    history.replaceState =
        function () {
            originalReplaceState.apply(this, arguments)
            updateHref()
        }

    window.addEventListener('popstate', updateHref)
    window.addEventListener('hashchange', updateHref)

    return {
        subscribe: derived(
                href,
                ( $href ) =>
                    new URL( $href ) )
                        .subscribe
    }
}
