<script>
    // -- IMPORTS

    import { link, Link, navigate } from 'svelte-routing';
    import { getLocalizedText } from 'senselogic-gist';
    import { enabledAdminView } from '$lib/store/adminStore';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import page from '../../store/pageStore';
    import { textStore } from '$lib/store/textStore';
    import Button from '../element/Button.svelte';
    import LanguageToggler from '../header/LanguageToggler.svelte';
    import MobileNavigationIcon from '../header/MobileNavigationIcon.svelte';
    import AdminEditButton from '../element/AdminEditButton.svelte';
    import { user } from '../../store/userStore';
    import Switch from '../element/Switch.svelte';
    import urlStore from '$src/lib/store/urlStore';

    // -- VARIABLES

    let isMobileNavigationOpen = false;

    // -- FUNCTIONS

    function handleMobileNavigationButtonClick()
    {
        isMobileNavigationOpen = !isMobileNavigationOpen;
    }

</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    header
    {
        z-index: 999;
        position: sticky;
        top: 0;

        width: 100%;
        max-width: 100vw;
        border-bottom: 2px solid #2a2a2a;
        padding: 1rem 2rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: darkGreyColor;
    }

    // -- CLASSES

    .header-logo
    {
        max-width: 12rem;

        object-fit: cover;
    }

    .header-navigation
    {
        min-width: 61vw;

        +media( smaller-desktop )
        {
            position: fixed;

            overflow: scroll;
            inset: 0 200%;
            height: 100vh;
            width: 100vw;

            display: flex;
            flex-direction: column;

            background-color: darkGreyColor;

            transition: inset 350ms ease-in-out;
            overscroll-behavior: contain;
        }
    }

    .header-navigation.is-open
    {
        +media( smaller-desktop )
        {
            inset: 0 0;
        }
    }

    .header-navigation-links-list
    {
        width: 100%;

        display: flex;
        gap: 2.5rem;
        justify-content: space-between;
        align-items: center;

        +media( smaller-desktop )
        {
            margin-top: 5rem;
            padding: 1rem 2rem;

            flex-direction: column;
            gap: 2rem;
            align-items: flex-start;
        }

        +media( desktop )
        {
            gap: 2rem;
        }
    }

    .header-navigation-link-container
    {
        margin: 0;
        padding: 0.75rem 1.25rem;

        +media( smaller-desktop )
        {
            width: 100%;
            border-bottom: 1px solid #2A2A2A;
            padding: 1rem 0;
        }
    }

    :global( .header-navigation-link )
    {
        font-size: 1rem;
        font-weight: 250;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: lightGreyColor;

        cursor: pointer;
        transition: all 0.1s ease-in-out;

        +media( smaller-desktop )
        {
            line-height: 2rem;
            font-size: 1.5rem;
        }
    }

    :global( .header-navigation-link:hover )
    {
        color: lightGoldColor;
    }

    .mobile-navigation-button
    {
        outline: none;
        height: 3rem;
        width: 3rem;
        border: none;

        display: none;

        background-color: unset;

        cursor: pointer;

        +media( smaller-desktop )
        {
            z-index: 999;

            display: block;
        }
    }
</style>

<header>
    <Link
        to="/{ $languageTagStore }"
        on:click={ () => isMobileNavigationOpen = false }
    >
        <img class="header-logo" src="/image/icon/logo.svg" alt="logo"/>
    </Link>
    <nav class="header-navigation" class:is-open={ isMobileNavigationOpen }>
        <ul class="header-navigation-links-list">
            <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>    
                <Link
                    class="header-navigation-link"
                    to="/{ $languageTagStore }/team"
                    on:click={ () => isMobileNavigationOpen = false }
                >
                    { getLocalizedText( $textStore[ 'team-label' ], $languageTagStore ) }
                </Link>

                <AdminEditButton
                    type="text"
                    slug="team-label"
                />
            </li>
            <!-- <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>
                <Link
                    class="header-navigation-link"
                    to="/{ $languageTagStore }/networking"
                    on:click={ () => isMobileNavigationOpen = false }
                >
                    { getLocalizedText( $textStore[ 'networking-label' ], $languageTagStore ) }
                </Link>

                <AdminEditButton
                    type="text"
                    slug="networking-label"
                />
            </li>
            <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>
                <Link
                    class="header-navigation-link"
                    to="/{ $languageTagStore }/digitalization"
                    on:click={ () => isMobileNavigationOpen = false }
                >
                    { getLocalizedText( $textStore[ 'digitalization-label' ], $languageTagStore ) }
                </Link>

                <AdminEditButton
                    type="text"
                    slug="digitalization-label"
                />
            </li> -->
            <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>
                <Link
                    class="header-navigation-link"
                    to="/{ $languageTagStore }/hubs"
                    on:click={ () => isMobileNavigationOpen = false }
                >
                    { getLocalizedText( $textStore[ 'hubs-label' ], $languageTagStore ) }
                </Link>

                <AdminEditButton
                    type="text"
                    slug="hubs-label"
                />
            </li>
            <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>
                <Link
                    class="header-navigation-link"
                    to="/{ $languageTagStore }/membership"
                    on:click={ () => isMobileNavigationOpen = false }
                >
                    { getLocalizedText( $textStore[ 'membership-label' ], $languageTagStore ) }
                </Link>

                <AdminEditButton
                    type="text"
                    slug="membership-label"
                />
            </li>
            <li class="header-navigation-link-container" class:is-admin-enabled={ $enabledAdminView }>
                <Link
                    class="header-navigation-link"
                    to="/{ $languageTagStore }/news"
                    on:click={ () => isMobileNavigationOpen = false }
                >
                    { getLocalizedText( $textStore[ 'news-label' ], $languageTagStore ) }
                </Link>

                <AdminEditButton
                    type="text"
                    slug="news-label"
                />
            </li>
            { #key $page.url.href }
            <li class:is-admin-enabled={ $enabledAdminView }>
                <LanguageToggler bind:isMobileNavigationOpen />

                <AdminEditButton
                    type="#language"
                />
            </li>
            { /key }
            { #if $user }
                { #if $user.role === 'authenticated' }
                    <li>
                        <p class="font-size-100 text-align-center">Admin</p>
                        <Switch bind:value={ $enabledAdminView }/>
                    </li>
                { /if }
            { /if }

            <li class:is-admin-enabled={ $enabledAdminView }>
                <Button
                    fullWidth={ false }
                    on:click={ () => navigate( `/${ $languageTagStore }/become-member` ) }
                >
                    { getLocalizedText( $textStore[ 'become-member-label' ], $languageTagStore ) }

                </Button>

                <AdminEditButton
                    type="text"
                    slug="become-member-label"
                />
            </li>
            <!-- <Button
                fullWidth={ false }
                invertColor
                on:click={ () => goto( `/${ $languageTagStore }/login` ) }
            >
                { getLocalizedText( $textStore[ 'login-label' ], $languageTagStore ) }
            </Button> -->
        </ul>
    </nav>
    <button
        class="mobile-navigation-button"
        aria-label="Menu"
        on:click={ handleMobileNavigationButtonClick }
    >
        <MobileNavigationIcon { isMobileNavigationOpen } />
    </button>
</header>
