// -- IMPORTS

import {  writable } from 'svelte/store';

// -- VARIABLES

let queryString = window.location.search;
let urlParams = new URLSearchParams(queryString)

let pageDataStore = writable( {
    imagePath: "",
    url: window.location,
    params: urlParams
}
);

export default pageDataStore
