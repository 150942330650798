// -- IMPORTS

import { writable } from 'svelte/store'

// -- CONSTANTS

export let userLocationStore = writable(
    {
        countryCode : '',
        isNorthAmerica: false,
    }
)
