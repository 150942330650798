<script>
    // -- IMPORTS

    import { websiteUrl } from '$lib/base.js';
    import Seo from '$lib/component/element/Seo.svelte';
    import { enabledAdminView } from '$lib/store/adminStore';
    import { getImagePath } from '$lib/filePath';
    import { getLocalizedText } from 'senselogic-gist';
    import { getProcessedMultilineTranslatedText } from '$lib/base';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { onMount } from 'svelte';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import IconButton from '$lib/component/element/IconButton.svelte';
    import Siema from 'siema';
    import { fetchData } from '../base';
    import { pageStore } from '../store/pageDataStore';
    import { languageArrayStore } from '../store/languageArrayStore';
    import FullLoading from '../component/Layout/FullLoading.svelte';
  import urlStore from '../store/urlStore';

    // -- VARIABLES

    let isLoading = true;
    let metaTitle = '';
    let metaDescription = '';
    let metaKeywords = '';
    let imagePath = '';
    let url = '';

    let newsPage = $pageStore.page.newsPage;
    let newsBlockArray = $pageStore.block.newsSection;
    let headingCarouselElement;
    let headingCarousel;
    let activeSlideIndex = 0;

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true

            let newsPageData = 
                await fetchData(
                    '/api/get-news',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                route: '/news'
                            }
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            $pageStore.page.newsPage = newsPageData.page;
            $pageStore.block.newsSection = newsPageData.blockArray;
            newsBlockArray = newsPageData.blockArray;
            newsPage = newsPageData.page;
            metaTitle = newsPageData.page.metaTitle ?? newsPageData.page.title;
            metaDescription = newsPageData.page.metaDescription ?? newsPageData.page.subTitle;
            metaKeywords = newsPageData.page.metaKeywords;
            imagePath = newsPageData.page.imagePath;
            url = websiteUrl;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // ~~

    function handleCarouselChange(
        )
    {
        activeSlideIndex = this.currentSlide;
    }

    // ~~

    function createCarousel(
        )
    {
        headingCarousel = new Siema(
                {
                    selector : headingCarouselElement,
                    loop: true,
                    draggable: true,
                    onChange: handleCarouselChange,
                }
                );

        return (
            () => {
                headingCarousel.destroy( true );
            }
        );
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            if( newsPage === undefined || newsBlockArray === undefined ) {
                loadData()
            }
            else
            {
                isLoading = false;
            }
        }
        );

    $:  if ( headingCarouselElement && !isLoading )
        {
            createCarousel()
        }

    $: if ( $urlStore.pathname.includes( 'news' ) )
    {
        document.title = 'News / Gallery';
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

     // -- CLASSES

    .news-section
    {
        overflow: hidden;
        border: 1px solid borderColor;
        padding: 0 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 2rem;

        +media( tablet )
        {
            max-width: 60vw;
            border: none;
        }

        +media( desktop )
        {
            max-width: 77vw;

            gap: 5rem;
        }
    }

    .page-heading
    {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .page-heading .page-heading-subtitle
    {
        text-transform: none !important;
    }

    .image-container
    {
        margin-top: -5rem;
        width: 100%;

        display: flex;
        flex-direction: column;

        +media( desktop )
        {
            border-bottom: 2px solid grayColor500;
            padding-bottom: 5rem;

            gap: 2rem;
        }
    }

    .image-container-text
    {
        +media( desktop )
        {
            text-align: center;
        }
    }

    .block-list
    {
        margin-bottom: 4rem;

        display: flex;
        flex-direction: column;
        gap: 4rem;

        +media( desktop )
        {
            margin-top: 2.5rem;
            margin-bottom: 7.5rem;

            gap: 7.5rem;
        }
    }

    .block-item
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        +media( desktop )
        {
            flex-direction: row;
            gap: 5rem;
        }
    }

    .block-item.right
    {
        +media( desktop )
        {
            flex-direction: row-reverse;
        }
    }

    .block-item-image
    {
        height: 18.75rem;
        width: 100%;

        align-self: stretch;

        +media( desktop )
        {
            height: 40rem;
            width: 44.375rem;

            flex-shrink: 0;
        }
    }

    .block-item-text
    {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
    }

    .news-corousel
    {
        width: 100%;
    }

    .news-corousel-container
    {
        position: relative;
    }

    .news-carousel-item
    {
        height: 15rem;
        width: 100%;

        object-fit: cover;

        +media( desktop )
        {
            height: 39.5rem;
        }
    }

    .news-carousel-next-button,
    .news-carousel-prev-button
    {
        position: absolute;
        top: 50%;
    }

    .news-carousel-next-button
    {
        top: 50%;
        right: 1.5rem;
        transform: translateY( -50% );
    }

    .news-carousel-prev-button
    {
        top: 50%;
        left: 0.75rem;
        transform: translateY( -50% );
    }

    .carousel-slide-dots-container
    {
        position: absolute;
        bottom: 3rem;
        left: 50%;
        transform: translateX( -50% );

        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    .carousel-slide-dot
    {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50%;

        background: rgba( 255, 255, 255, 0.60 );

        cursor: pointer;
    }

    .carousel-slide-dot.active
    {
        height: 0.625rem;
        width: 0.625rem;

        background: whiteColor;
    }

    .title
    {
        font-size: 1.4375rem;
        font-weight: 500;
        letter-spacing: 0.08625rem;
        text-transform: uppercase;
        color: lightGoldColor;
    }

    .text
    {
        line-height: 2rem;
        font-size: 1.125rem;

        +media( desktop )
        {
            line-height: 2.25rem;
            font-size: 1.25rem;
            letter-spacing: 0.03rem;
            color: lightGrayColor;
        }
    }
</style>

{ #if isLoading }
    <FullLoading />
{ :else }
    <Seo
        metaTitle={ metaTitle }
        metaDescription={ metaDescription }
        metaKeywords={ metaKeywords }
        url={ url }
        imagePath={ imagePath }
        languageData={ $languageArrayStore }
        path=''
    />

    <section class="news-section" class:is-admin-enabled={ $enabledAdminView }>
        <AdminEditButton
            inset="5rem 5rem auto auto"
            type="news"
        />

        { #await import( '$lib/component/header/PageHeading.svelte' ) then { default : PageHeading } }
            <PageHeading
                title={ newsPage?.title || '' }
                subtitle={ newsPage?.subTitle || '' }
            />
        { /await }

        <div class="image-container">
            { #if newsPage.imagePathArray.length > 0 }
                <div class="news-corousel-container">
                    <div class="news-corousel" bind:this={ headingCarouselElement }>
                        { #each newsPage.imagePathArray as imagePath }
                            { #if imagePath }
                                <img
                                    loading="lazy"
                                    src={ getImagePath( imagePath ) }
                                    alt=""
                                    class="news-carousel-item"
                                />
                            { /if }
                        { /each }
                    </div>
                    <div class="news-carousel-prev-button">
                        <IconButton on:click={ () => headingCarousel.prev() }>
                            <div class="white-arrow-left-icon size-250"/>
                        </IconButton>
                    </div>
                    <div class="news-carousel-next-button">
                        <IconButton on:click={ () => headingCarousel.next() }>
                            <div class="white-arrow-right-icon size-250"/>
                        </IconButton>
                    </div>
                    <div class="carousel-slide-dots-container">
                        { #each Array.from( { length: newsPage.imagePathArray.length } ) as _, dotIndex }
                            <div class="carousel-slide-dot" class:active={ dotIndex === activeSlideIndex }></div>
                        { /each }
                    </div>
                </div>
            { /if }

            <div class="image-container-text">
                { #if newsPage.additionalTitle }
                    <p class="font-size-200 font-weight-500 color-light-gold text-transform-uppercase line-height-300">
                        <AnimatedText text={ getProcessedMultilineTranslatedText( newsPage.additionalTitle || '', $languageTagStore ) }/>
                    </p>
                { /if }
                { #if newsPage.additionalDescription }
                    <p class="font-size-125 color-light-grey margin-top-50 line-height-225">
                        <AnimatedText text={ getProcessedMultilineTranslatedText( newsPage.additionalDescription || '', $languageTagStore ) }/>
                    </p>
                { /if }
            </div>
        </div>

        <div class="block-list">
            { #each newsBlockArray as newsBlock, newsBlockIndex }
                <div
                    class="block-item { newsBlock.imageSide }"
                    class:is-admin-enabled={ $enabledAdminView }
                >
                    { #if newsBlock.imagePath }
                        <AnimatedContainer delay="{ newsBlockIndex * 100 }ms">
                            <img
                                class="block-item-image"
                                src={ getImagePath( newsBlock.imagePath ) }
                                alt={ newsBlock.imageLegend || newsBlock.title }
                                style="object-fit: { newsBlock.imageFit };object-position: { newsBlock.imageHorizontalPosition } { newsBlock.imageVerticalPosition };"
                            />
                        </AnimatedContainer>
                    { /if }

                    <AnimatedContainer delay="{ newsBlockIndex * 100 }ms">
                        <div class="block-item-text">
                            { #if newsBlock.title }
                                <p class="title">{ getLocalizedText( newsBlock.title, $languageTagStore ) }</p>
                            { /if }

                            { #if newsBlock.text }
                                <p class="text">{ @html getProcessedMultilineTranslatedText( newsBlock.text, $languageTagStore ) }</p>
                            { /if }
                        </div>
                    </AnimatedContainer>

                    <AdminEditButton
                        type="block"
                        id={ newsBlock.id }
                        inset="0 0 auto auto"
                    />
                </div>
            { /each }
        </div>
    </section>
{ /if }
