<script>
    // -- IMPORTS

    import { websiteUrl, getProcessedMultilineTranslatedText } from '$lib/base.js';
    import Seo from '$lib/component/element/Seo.svelte';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import { getImagePath } from '$lib/filePath';
    import { getLocalizedText } from 'senselogic-gist';
    import { navigate } from 'svelte-routing';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { selectedPlan } from '$lib/store/planStore';
    import { textStore } from '$lib/store/textStore';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import { pageStore } from '../store/pageDataStore';
    import { fetchData } from '../base';
    import { languageArrayStore } from '../store/languageArrayStore';
    import { onMount } from 'svelte';
    import FullLoading from '../component/Layout/FullLoading.svelte';
  import urlStore from '../store/urlStore';
  import MembershipPlanTable from '../component/element/MembershipPlanTable.svelte';
  import CustomizedServicesTable from '../component/membership/CustomizedServicesTable.svelte';
  import CustomizedServicesFromBamhubTable from '../component/membership/CustomizedServicesFromBamhubTable.svelte';
  import ServicesOfferedByValBusinTable from '../component/membership/ServicesOfferedByValBusinTable.svelte';
  import ServicesOfferedViaBamTable from '../component/membership/ServicesOfferedViaBamTable.svelte';

    // -- VARIABLES

    let isLoading = true;
    let metaTitle = '';
    let metaDescription = '';
    let metaKeywords = '';
    let imagePath = '';
    let url = '';

    let membershipPage = $pageStore.page.membershipPage;
    let membershipBlockArray = $pageStore.block.membershipSection;

    // -- FUNCTIONS

    async function loadData()
    {
        try
        {
            isLoading = true

            let pageData =
                await fetchData(
                    '/api/get-membership',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                route: '/membership'
                            },
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            console.log( pageData.blockArray )
            $pageStore.page.membershipPage = pageData.page;
            $pageStore.block.membershipSection = pageData.blockArray;

            membershipPage = pageData.page;
            membershipBlockArray = pageData.blockArray

            metaTitle = pageData.page.metaTitle ?? pageData.page.title;
            metaDescription = pageData.page.metaDescription ?? pageData.page.subTitle;
            metaKeywords = pageData.page.metaKeywords;
            imagePath = pageData.page.imagePath;
            url = websiteUrl;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            if
            (
                membershipPage === undefined
                || membershipBlockArray === undefined
            )
            {
                loadData()
            }
            else
            {
                isLoading = false;
            }
        }
    );

    $: if ( $urlStore.pathname.includes( 'membership' ) )
        {
            document.title = 'Membership';
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

     // -- CLASSES

     .membership-section
     {
        border: 1px solid borderColor;
        padding: 2.5rem 1.5rem 5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 2rem;

        +media( tablet )
        {
            max-width: 60vw;
            border: none;
        }

        +media( desktop )
        {
            max-width: 77vw;
            padding: 0 0 7.5rem 0;
        }
     }

     .membership-heading-image
     {
        height: 27.5rem;
        width: 100%;
        aspect-ratio: 3 / 4;

        object-fit: cover;

        +media( desktop )
        {
            height: 37.5rem;
        }
     }

     .separator-line-vector
     {
        height: 4.4375rem;
        width: 100%;

        background: url("/image/icon/vertical_line.svg") center center no-repeat;

        +media( desktop )
        {
            height: 8rem;
            stroke-width: 100px;
        }
     }

     .separator-line-vector.twice-shorter
     {
        height: calc( 4.4375rem / 2 );

        +media( desktop )
        {
            height: calc( 8rem / 2 );
        }
     }

     .membership-card-item
     {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        align-selft: stretch;

        +media( desktop )
        {
            gap: 0.5rem;
        }
     }

    .membership-card-title
    {
        line-height: 2.5rem;
        font-size: 2rem;
        letter-spacing: 0.16rem;
        text-align: center;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            font-weight: 250;
            letter-spacing: 0.48rem;
            text-align: center;
        }
    }

    .membership-benefit-list
    {
        margin-top: 0.5rem;

        display: flex;
        flex-direction: column;
        gap: 2rem;

        +media( desktop )
        {
            margin-top: 1.5rem;

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: 1.5rem;
            column-gap: 2.5rem;
        }
    }

    .membership-benefit-item
    {
        height: 100%;

        display: flex;
        gap: 1rem;
        align-items: center;
    }

    .icon
    {
        width: 3rem;

        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
    }

    .flowchart-image
    {
        margin-top: 0;
        height: 20rem;
        width: 20rem;

        +media( desktop )
        {
            margin-top: 3rem;
            height: 40rem;
            width: 40rem;
        }
    }

    .separator-line-vector:nth-of-type( 1 )
    {
        margin-top: 2rem;
        height: 5rem;
    }

    .block-text
    {
        line-height: 2.25rem;
        font-size: 1.5rem;
        letter-spacing: 0.03rem;
        text-align: center;
        color: lightGreyColor;
    }

    .become-member-button-container
    {
        z-index: 998;
        position: fixed;
        bottom: 2.5rem;

        width: 100%;
        max-width: 17.5rem;

        +media( desktop )
        {
            max-width: 25rem;
        }
    }

    .become-member-button-label
    {
        line-height: 1.5rem;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.15rem;

        +media( desktop )
        {
            font-size: 1.5rem;
        }
    }
</style>

{ #if  isLoading }
    <FullLoading />
{ :else }
    <Seo
        metaTitle={ metaTitle }
        metaDescription={ metaDescription }
        metaKeywords={ metaKeywords }
        url={ url }
        imagePath={ imagePath }
        languageData={ $languageArrayStore }
        path=''
    />

    { #await import( '../component/header/PageHeading.svelte' ) then { default : PageHeading } }
        <PageHeading
            title={ membershipPage?.title || '' }
            subtitle={ membershipPage?.subTitle || '' }
        />
    { /await }

    <AdminEditButton
        type="page"
        id={ membershipPage.id }
        inset="10rem 5rem auto auto"
    />

    { #if membershipPage.imagePath }
        <img src={ getImagePath( membershipPage.imagePath ) } alt={ membershipPage.imageAlt } class="membership-heading-image">
    { /if }

    { #key $languageTagStore }
        <section class="membership-section">
            { #each membershipBlockArray as block, blockIndex }
                { #if blockIndex === 0 }
                    <div class="separator-line-vector"/>
                { /if }
                { #if block.typeSlug === 'title-and-text' }
                    <div class="membership-card-item" class:is-admin-enabled={ $enabledAdminView }>
                        { #if block.title }
                            <p class="membership-card-title color-light-gold">
                                <AnimatedText text={ getLocalizedText( block.title, $languageTagStore ) }/>
                            </p>
                        { /if }
                        { #if block.text }
                            <p class="description-text color-light-gray" style="text-align: center;"><AnimatedText text={ getLocalizedText( block.text, $languageTagStore ) }/></p>
                        { /if }
                        <AdminEditButton
                            type="block"
                            id={ block.id }
                        />
                    </div>
                    { #if blockIndex <= membershipBlockArray.length && ( membershipBlockArray[ blockIndex + 1 ] || membershipBlockArray.at( -1 ) ).typeSlug !== 'list-image-and-text' }
                        <div class="separator-line-vector"/>
                    { /if }
                { :else if block.typeSlug === 'text' }
                    <div class="block-text" class:is-admin-enabled={ $enabledAdminView }>
                        <span class="color-light-gold">{ @html getProcessedMultilineTranslatedText( block.text || '' ) }</span>
                        <AdminEditButton
                            type="block"
                            id={ block.id }
                        />
                    </div>
                    { #if blockIndex <= membershipBlockArray.length && ( membershipBlockArray[ blockIndex + 1 ] || membershipBlockArray.at( -1 ) ).typeSlug !== 'list-image-and-text' }
                        <div class="separator-line-vector twice-shorter"/>
                    { /if }
                { :else if block.typeSlug === 'membership-plans-table' }
                    <div class="membership-card-item" class:is-admin-enabled={ $enabledAdminView }>
                        { #if block.title }
                            <p class="membership-card-title color-light-gold">
                                <AnimatedText text={ getProcessedMultilineTranslatedText( block.title || '', $languageTagStore ) }/>
                            </p>
                        { /if }
                        { #if block.text }
                            <p class="description-text color-light-gray" style="text-align: center;"><AnimatedText text={ getLocalizedText( block.text || '', $languageTagStore ) }/></p>
                        { /if }

                        <MembershipPlanTable />

                        <AdminEditButton
                            type="plan"
                        />
                    </div>
                    { #if blockIndex <= membershipBlockArray.length && ( membershipBlockArray[ blockIndex + 1 ] || membershipBlockArray.at( -1 ) ).typeSlug !== 'list-image-and-text' }
                        <div class="separator-line-vector twice-shorter"/>
                    { /if }
                { :else if block.typeSlug === 'customized-services-table' }
                    <div class:is-admin-enabled={ $enabledAdminView }>
                        <CustomizedServicesTable />
                        <AdminEditButton
                            type="plan"
                        />
                    </div>

                    { #if blockIndex <= membershipBlockArray.length && ( membershipBlockArray[ blockIndex + 1 ] || membershipBlockArray.at( -1 ) ).typeSlug !== 'list-image-and-text' }
                        <div class="separator-line-vector twice-shorter"/>
                    { /if }
                { :else if block.typeSlug === 'customized-services-from-bamhub-table' }
                    <div class:is-admin-enabled={ $enabledAdminView }>
                        <CustomizedServicesFromBamhubTable />
                        <AdminEditButton
                            type="plan"
                        />
                    </div>

                    { #if blockIndex <= membershipBlockArray.length && ( membershipBlockArray[ blockIndex + 1 ] || membershipBlockArray.at( -1 ) ).typeSlug !== 'list-image-and-text' }
                        <div class="separator-line-vector twice-shorter"/>
                    { /if }
                { :else if block.typeSlug === 'services-offered-by-val-e-busin-table' }
                    <div class:is-admin-enabled={ $enabledAdminView }>
                        <ServicesOfferedByValBusinTable />
                        <AdminEditButton
                            type="plan"
                        />
                    </div>
                    { #if blockIndex <= membershipBlockArray.length && ( membershipBlockArray[ blockIndex + 1 ] || membershipBlockArray.at( -1 ) ).typeSlug !== 'list-image-and-text' }
                        <div class="separator-line-vector twice-shorter"/>
                    { /if }
                { :else if block.typeSlug === 'services-offered-via-bam-table' }
                    <div class:is-admin-enabled={ $enabledAdminView }>
                        <ServicesOfferedViaBamTable />
                        <AdminEditButton
                            type="plan"
                        />
                    </div>
                { :else if block.typeSlug === 'list-image-and-text' }
                    <ul class="membership-benefit-list" class:is-admin-enabled={ $enabledAdminView }>
                        { #each block.textArray as { iconPath, text } }
                            <AnimatedContainer style="align-self: stretch;">
                                <li class="membership-benefit-item">
                                    { #if iconPath }
                                        <span class="icon">
                                            <img loading="lazy" src={ getImagePath( iconPath ) }  aria-hidden="true" alt="">
                                        </span>
                                    { /if }

                                    { #if text }
                                        <p class="font-size-125 color-light-gray">{ getLocalizedText( text, $languageTagStore ) }</p>
                                    { /if }
                                </li>
                            </AnimatedContainer>
                        { /each }
                        <AdminEditButton
                            type="block"
                            id={ block.id }
                        />
                    </ul>

                    { #if blockIndex <= membershipBlockArray.length }
                        <div class="separator-line-vector"/>
                    { /if }
                { :else if block.typeSlug === 'image' }
                    <div class:is-admin-enabled={ $enabledAdminView }>
                        { #if block.imagePath }
                            <img loading="lazy" src={ getImagePath( block.imagePath ) } alt="" class="flowchart-image" aria-hidden="true"/>
                        { /if }

                        <AdminEditButton
                            type="block"
                            id={ block.id }
                        />
                    </div>
                { /if }
            { /each }

            { #if $selectedPlan }
                { #await import( '../component/element/Button.svelte' ) then { default : Button } }
                    <div class="become-member-button-container">
                        <Button
                            fullWidth
                            on:click={ () => navigate( `/${ $languageTagStore }/become-member` ) }
                            style="height: 4.5rem; box-shadow: 0 3px 5px #49403a"
                        >
                                <span class="become-member-button-label">
                                    { getLocalizedText( $textStore[ 'validate-selection-label' ] || '', $languageTagStore ) }
                                </span>
                            <AdminEditButton
                                type="text"
                                slug="validate-selection-label"
                            />
                        </Button>
                    </div>
                { /await }
            { /if }
        </section>
    { /key }
{ /if }
