<script>
  import Seo from '../component/element/Seo.svelte';
  import FoundersSection from '../component/homePage/FoundersSection.svelte';
  import { languageArrayStore } from '../store/languageArrayStore';
  import urlStore from '../store/urlStore';

    $: if ( $urlStore.pathname.includes( 'team' ) )
        {
            document.title = 'Team';
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .home-image-container
    {
        height: 32.5rem;
        width: 100%;

        +media( smaller-32em )
        {
            background-position: center right 30% !important;
        }
    }
</style>

<Seo
    metaTitle={ "Bamhub - Team" }
    metaDescription={ "Get to know a little more about our team and our ambassadors" }
    url={ 'https://bamhub.com' }
    languageData={ $languageArrayStore }
    path="team"
/>

<FoundersSection />

{ #await import( '../component/homePage/ManagerSection.svelte' ) then { default : ManagerSection } }
    <ManagerSection/>
{ /await }