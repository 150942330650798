<script>
  import Loading from "../element/Loading.svelte";

</script>
<style lang="stylus">
    .full-loading-container
    {
        height: 100dvh;
        width: 100dvw;

        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<div class="full-loading-container" >
    <Loading size="5rem" />
</div>
