<script>
    // -- IMPORTS

    import { enabledAdminView } from '$lib/store/adminStore.js';
    import Loading from './Loading.svelte';

    // -- VARIABLES

    export let fullWidth = false;
    export let invertColor = false;
    export let type = 'button';
    export let style = null;
    export let disabled = false;
    export let href = null;
    export let variant = '';
    export let loading = false;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    button,
    a
    {
        min-width: 10.6125rem;
        border: 1px solid lightGoldColor;
        border-radius: 0;
        padding: 0.75rem 1.25rem;

        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        background-color: darkGreyColor;

        font-size: 1rem;
        text-transform: uppercase;
        color: lightGoldColor;

        cursor: pointer;
        text-wrap: nowrap;
        width: fit-content;

        transition: all 0.2s ease-in-out;

        +media( smaller-desktop )
        {
            width: 100%;
            max-width: unset;
        }
    }

    button[disabled]
    {
        pointer-events: none;
        cursor: not-allowed;
    }

    button.full-width,
    a.full-width,
    {
        width: 100%;
        max-width: unset;
    }

    button.invert-color,
    a.invert-color
    {
        border: 1px solid lightGoldColor;

        background: lightGoldColor;

        color: darkGreyColor;
    }

    button:hover,
    button:focus,
    a:hover,
    a:focus
    {
        background-color: lightGoldColor;

        color: darkGreyColor;
    }

    button.invert-color:hover,
    button.invert-color:focus,
    a.invert-color:hover,
    a.invert-color:focus
    {
        background-color: darkGreyColor;

        color: lightGoldColor;
    }

    button:disabled,
    button[disabled],
    button[disabled]:hover
    {
        cursor: not-allowed;
    }

    // -- CLASSES

    .error
    {
        border-color:  redColor500;

        background: darkGreyColor;

        color: redColor500;
    }

    .error:hover,
    .error:focus
    {
        background: redColor500;

        color: darkGreyColor;
    }
</style>

{ #if href }
    <a class="{ variant }" href={ href } class:full-width={ fullWidth } class:invert-color={ invertColor } style={ style } { type } { disabled } on:click class:is-admin-enabled={ $enabledAdminView }>
        <slot/>
    </a>
{ :else }
    <button class={ variant } class:full-width={ fullWidth } class:invert-color={ invertColor } style={ style } { type } disabled={ loading || disabled } on:click class:is-admin-enabled={ $enabledAdminView }>
        { #if loading }
            <Loading/>
        { :else }
            <slot/>
        { /if }
    </button>
{ /if }
