<script>
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import { getLocalizedText, getMap } from 'senselogic-gist';
    import { getProcessedMultilineTranslatedText } from '$lib/base';
    import { selectedPlan, selectedServiceOfferedViaBam } from '$lib/store/planStore.js';
    import { languageTagStore } from '$lib/store/languageTagStore.js';
    import { textStore } from '$lib/store/textStore.js';
    import { onMount } from 'svelte';
    import { fetchData } from '../../base';
    import { membershipStore } from '../../store/membershipStore';
  import Loading from '../element/Loading.svelte';

    // -- VARIABLES

    let serviceArray = $membershipStore.data.servicesOfferedViaBamArray;
    let planByNameId = getMap( serviceArray, 'id' );
    let isLoading = true;

    // -- FUNCTIONS

    async function loadData()
    {
        try
        {
            isLoading = true;

            let serviceArrayData = 
                await fetchData(
                    '/api/plan/get-membership-extra-service-array',
                    {
                        method: 'POST',
                        body:  JSON.stringify(
                            {
                                extraServiceType: 'service-offered-via-bam-type',
                                isInflated: true
                            }
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            $membershipStore.data.servicesOfferedViaBamArray = serviceArrayData.membershipExtraServiceArray;

            serviceArray = serviceArrayData.membershipExtraServiceArray;
            planByNameId = getMap( serviceArray, 'id' );
        }
        catch ( error )
        {
            console.log( error );
        }
        finally
        {
            isLoading = false;
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            if ( serviceArray === undefined )
            {
                loadData();
            }
            else
            {
                isLoading = false;
                planByNameId = getMap( serviceArray, 'id' )
            }
        }
    )
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    button
    {
        cursor: pointer;
    }

    // -- CLASSES

    .container
    {
        width: 100%;
        padding-top: 2.5rem;

        display: none;
        flex-direction: column;
        gap: 2rem;

        background-color: darkGreyColor;

        +media( desktop )
        {
            max-width: 77vw;

            display: flex;
        }
    }

    .title
    {
        font-weight: 250 !important;
        text-align: center;
        color: lightGoldColor;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            letter-spacing: 0.48rem;
            text-transform: uppercase;
        }
    }

    .text
    {
        border-bottom: 1px solid goldColor500;
        border-left: 1px solid goldColor500;
        padding: 1rem 1.5rem;

        display: flex;
        gap: 1rem;
        align-items: flex-start;
        align-self: stretch;

        color: lightGreyColor;
    }

    .value
    {
        border: 1px solid goldColor500;
        padding: 0.5rem 1rem;

        gap: 1rem;
    }

    .icon-value
    {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon
    {
        height: 2.5rem;
        width: 2.5rem;

        flex-shrink: 0;
    }

    .select-button
    {
        border: 1px solid darkGreyColor;
        padding: 0.75rem 1.25rem;

        display: flex;
        flex: 1 0 0;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        background: lightGoldColor;
    }

    .select-button.is-active
    {
        background: darkGreyColor;

        color: lightGoldColor;
    }

    .select-plan-cell
    {
        border-right: 1px solid goldColor500;
        padding: 1rem;
    }

    .plan-cell
    {
        min-width: 14rem;
        border-top: 1px solid goldColor500;
        border-bottom: 1px solid goldColor500;
        border-right: 1px solid goldColor500;
        padding: 0.5rem 0rem;

        font-size: 1.25rem;
        text-align: center;
        color: lightGoldColor;
    }

    .plan-cell.name
    {
        line-height: 1.75rem;
        font-weight: 700;
        letter-spacing: -0.025rem;
    }

    .checkbox-wrapper
    {
        +media( desktop )
        {
            display: flex;
            justify-content: center;
        }
    }

    .checkbox-wrapper input[type="checkbox"]
    {
        display: none;

        visibility: hidden;
    }

    .checkbox-wrapper .cbx
    {
        margin: auto;
        -webkit-user-select: none;
        display: flex;
        gap: 0.5rem;

        user-select: none;
        cursor: pointer;
    }

    .checkbox-wrapper .cbx span
    {
        transform: translate3d(0, 0, 0);

        display: inline-block;
        align-items: center;

        line-height: 2rem;
        font-size: 1.25rem;
        vertical-align: middle;
        color: lightGreyColor;
    }

    .checkbox-wrapper .cbx:hover span
    {
        color: lightGoldColor;
    }

    .checkbox-wrapper .cbx span:last-child
    {
        margin-left: 0.5rem;
    }

    .checkbox-wrapper .cbx span:first-child
    {
        position: relative;
        transform: scale( 1 );

        margin-top: 0.25rem;
        height: 1.5rem;
        width: 1.5rem;
        border: 2px solid lightGoldColor;

        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;

        vertical-align: middle;

        transition: all 0.2s ease;
    }

    .checkbox-wrapper .cbx span:first-child svg
    {
        position: absolute;
        fill: none;
        stroke: darkGreyColor;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 1rem;
        stroke-dashoffset: 1rem;
        transform: translate3d( 0, 0, 0 );

        transition: all 0.3s ease;
        transition-delay: 0.1s;
    }

    .checkbox-wrapper .cbx span:first-child:before
    {
        transform: scale( 0 );

        height: 100%;
        width: 100%;
        border-radius: 50%;

        display: block;

        content: "";
        opacity: 1;
        background: lightGoldColor;
    }

    .checkbox-wrapper .cbx:hover span:first-child
    {
        border-color: lightGoldColor;
    }

    .checkbox-wrapper .inp-cbx:checked + .cbx span:first-child
    {
        border-color: lightGoldColor;

        background: lightGoldColor;

        animation: wave 0.4s ease;
    }

    .checkbox-wrapper .inp-cbx:checked + .cbx span:first-child svg
    {
        stroke-dashoffset: 0;
    }

    .checkbox-wrapper .inp-cbx:checked + .cbx span:first-child:before
    {
        transform: scale( 3.5 );

        opacity: 0;

        transition: all 0.6s ease;
    }

    .mobile-table
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        +media( desktop )
        {
            display: none;
        }
    }

    .mobile-container
    {
        width: 100vw;
        padding-bottom: 2.5rem;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;

        background: darkGreyColor;
    }

    .mobile-header
    {
        width: 100%;

        display: flex;
    }

    .mobile-button
    {
        border: 1px solid lightGoldColor;
        padding: 0.75rem 1.25rem;

        display: flex;
        flex: 1;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        background: darkGreyColor;

        line-height: 1.5rem;
        font-size: 1rem;
        font-style: normal;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: lightGoldColor;
    }

    .mobile-button.is-active
    {
        background: lightGoldColor;

        color: darkGreyColor;
    }

    .mobile-content
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .mobile-content-item
    {
        width: 100%;
        border-bottom: 1px solid #9D9782;
        padding: 1rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;

        color: lightGreyColor;
    }

    .mobile-content-item-text
    {
        width: 100%;

        display: grid;
        grid-template-columns: 2.5rem 1fr;
        gap: 1rem;
    }

    div.mobile-content-item:last-child
    {
        border: none;
    }

    .mobile-title
    {
        display: initial;

        line-height: 3rem;
        font-size: 2rem;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0.08rem;
        text-align: center;
        text-transform: uppercase;
        color: lightGoldColor;
    }

    .checkbox-mobile
    {
        grid-column: 2 / 3;
    }

    .container
    {
        width: 100%;

        display: none;
        flex-direction: column;

        text-align: center;

        +media( desktop )
        {
            max-width: 77vw;

            display: flex;
        }
    }

    .table
    {
        display: grid;
        grid-template-columns: 1fr 1fr;

        background-color: darkGreyColor;
    }

    .header,
    .row
    {
        display: contents;
    }

    .header > div,
    .row > div
    {
        border-bottom: 1px solid lightGoldColor;
        padding: 10px;
    }

    .service
    {
        padding: 1rem 1.5rem;

        display: grid;
        grid-template-columns: 2.5rem 1fr;
        gap: 1rem;

        line-height: 2rem;
        font-size: 1.25rem;
        text-align: left;
    }

    .club,
    .prive,
    .exclusif
    {
        border-top: 1px solid lightGoldColor;
        border-right: 1px solid lightGoldColor;
        padding: 1rem 1.5rem;

        line-height: 1.75rem;
        font-size: 1.25rem;
        font-weight: 700;
        letter-spacing: -0.025rem;
        text-align: center;
        color: lightGoldColor;
    }

    .price
    {
        border-left: none;
        border-right: 1px solid lightGoldColor;
        padding: 0.5rem 0;

        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;
    }

    @media (max-width: 768px)
    {
        .table
        {
            grid-template-columns: 1fr;
        }

        .header,
        .row
        {
            display: block;
        }

        .header > div,
        .row > div
        {
            border-top: 1px solid lightGoldColor;
            border-bottom: none;
        }

        .service
        {
            text-align: center;
        }
    }

    .service:first-child
    {
        border-bottom: 1px solid lightGoldColor;
        border-left: 1px solid lightGoldColor;
        border-right: 1px solid lightGoldColor;
    }

    div.header > div.service
    {
        border-left: none;

        background-color: darkGreyColor;
    }

    .plan-name-column
    {
        height: 100%;
        width: 100%;

        display: grid;
        grid-template-columns: repeat( 3, 1fr );
    }

    .service.plan-name-column
    {
        padding: 0;

        gap: 0;
    }

    .helper-text
    {
        margin-top: 0.5rem;

        line-height: 1.125rem;
        font-size: 0.875rem;
        text-align: left;
        color: #BFC6CA;
    }

    // -- MIXINS

    @keyframes wave
    {
        50%
        {
            transform: scale( 0.9 );
        }
    }
</style>

{ #if isLoading }
    <Loading />
{ :else }
    <div class="container">
        <div class="table">
            <div class="header">
            <div class="service"></div>
            <div class="service plan-name-column">
                { #each serviceArray as plan }
                    <div class={ plan.id }>{ getLocalizedText( plan.name || '', $languageTagStore ) }</div>
                { /each }
            </div>
            </div>
            { #each planByNameId[ $selectedPlan?.id || 'club' ].serviceArray as service, serviceIndex }
                <div class="row">
                    <div class="service">
                        { #if planByNameId[ $selectedPlan?.id || 'club' ].serviceArray[ serviceIndex ].serviceType.iconImagePath }
                            <img class="icon" src={ getImagePath( planByNameId[ $selectedPlan?.id || 'club' ].serviceArray[ serviceIndex ].serviceType.iconImagePath ) } alt="">

                            { @html getProcessedMultilineTranslatedText( service.serviceType.text || '', { count : service.value + '' + ( service.valueSuffix || '' ) } ) }
                        { /if }
                    </div>

                    <div class="price">
                        <div class="checkbox-wrapper">
                            <input
                                type="checkbox"
                                class="inp-cbx"
                                bind:group={ $selectedServiceOfferedViaBam }
                                value={ service.id }
                                id={ service.id }
                                name={ service.id }
                            />
                            <label
                                for={ service.id }
                                class="cbx"
                            >
                                <span>
                                    <svg viewBox="0 0 12 10" height="12px" width="1rem">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg
                                    >
                                </span>
                                <span>
                                    { getLocalizedText(  $textStore[ 'offer-on-request' ], $languageTagStore ) }
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            { /each }
        <p class="helper-text">{ getLocalizedText( $textStore[ 'montants-affiches-hors-taxes-label' ] || '', $languageTagStore ) }</p>
        </div>
    </div>

    <div class="mobile-table">
        <div class="mobile-container">

            <div class="mobile-header">
                { #each serviceArray as plan }
                    <button type="button" class="mobile-button" class:is-active={ $selectedPlan?.id === plan.id } on:click={ () => $selectedPlan = plan }>{ getLocalizedText( plan.name, $languageTagStore ) }</button>
                { /each }
            </div>

            <div class="mobile-content">
                { #each planByNameId[ $selectedPlan?.id || 'club' ].serviceArray as service, serviceIndex }
                    { #if service.serviceValueType.id === 'boolean' }
                        { #if service.value === 'true' }
                            <div class="mobile-content-item font-weight-700 font-size-125 line-height-175 color-dark-grey text-align-left">
                                <div class="mobile-content-item-text">
                                    { #if service.serviceType.iconImagePath }
                                        <img src={ getImagePath( service.serviceType.iconImagePath ) } alt="" class="icon">
                                    { /if }

                                    <div>
                                        { @html getProcessedMultilineTranslatedText( service.serviceType.text || '' ) }
                                    </div>

                                    <div class="checkbox-mobile">
                                        <div class="checkbox-wrapper">
                                            <input
                                                type="checkbox"
                                                class="inp-cbx"
                                                bind:group={ $selectedServiceOfferedViaBam }
                                                value={ service.id }
                                                id={ service.id }
                                                name={ service.id }
                                            />
                                            <label
                                                for={ service.id }
                                                class="cbx"
                                            >
                                                <span>
                                                <svg viewBox="0 0 12 10" height="12px" width="1rem">
                                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg
                                                >
                                                </span>
                                                <span>
                                                    { getLocalizedText(  $textStore[ 'offer-on-request' ], $languageTagStore ) }
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        { /if }
                    { /if }

                    { #if service.serviceValueType.id === 'number' }
                        { #if Boolean( service.value ) }
                            <div class="mobile-content-item font-weight-700 font-size-125 line-height-175 color-dark-grey text-align-left">
                                <div class="mobile-content-item-text">
                                    { #if service.serviceType.iconImagePath }
                                        <img src={ getImagePath( service.serviceType.iconImagePath ) } alt="" class="icon">
                                    { /if }

                                    <span>
                                        { @html getProcessedMultilineTranslatedText( service.serviceType.text || '', { count : ( service.value + '' + ( service.serviceType.valueSuffix || '' ) ) } ) }
                                    </span>

                                    <div class="checkbox-mobile">
                                        <div class="checkbox-wrapper">
                                            <input
                                                type="checkbox"
                                                class="inp-cbx"
                                                bind:group={ $selectedServiceOfferedViaBam }
                                                value={ service.id }
                                                id={ service.id }
                                                name={ service.id }
                                            />
                                            <label
                                                for={ service.id }
                                                class="cbx"
                                            >
                                                <span>
                                                <svg viewBox="0 0 12 10" height="12px" width="1rem">
                                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg
                                                >
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        { /if }
                    { /if }
                { /each }
            </div>
        </div>
        <p class="helper-text">{ getLocalizedText( $textStore[ 'montants-affiches-hors-taxes-label' ] || '', $languageTagStore ) }</p>
    </div>
{ /if }
