import { navigate } from "svelte-routing";
import { navigationAdjustedStore } from "./store/navigationStore";
import { writable } from "svelte/store";

// -- VARIABLES

export let currentPathname = writable( window.location.pathname );

let originalPushState = history.pushState;

let originalReplaceState = history.replaceState;

// -- FUNCTIONS

function setPathname( newPath )
{
    currentPathname.set( newPath );
}

// ~~

history.pushState = function( state, title, url )
{
    originalPushState.call( this, state, title, url );
    setPathname( window.location.pathname );
};

// ~~

history.replaceState = function( state, title, url )
{
    originalReplaceState.call( this, state, title, url );
    setPathname( window.location.pathname );
};

// ~~

window.addEventListener( 'popstate', () =>
{
    setPathname( window.location.pathname );
}
);

// ~~

export function ensureLanguageTagInPath(
    location,
    languageTagStore
    )
{
    let pathSegments = location.pathname.split( '/' ).filter( Boolean );

    if ( location.pathname === '/' || ( pathSegments.length > 0 && pathSegments[ 0 ].length !== 2 ) )
    {
        let newPath = location.pathname === '/' ? `/${ languageTagStore }/` : `/${ languageTagStore }/${ pathSegments.join( '/' ) }`;

        if ( location.search )
        {
            newPath += location.search;
        }

        navigate( newPath, { replace: true } );
    }

    navigationAdjustedStore.set( true );
}
