// -- STATEMENTS

export default class Emitter
{
    // -- CONSTRUCTORS

    constructor(
        )
    {
        this.eventListenerMap = new Map();
    }

    // -- OPERATIONS

    on(
        eventName,
        listener
        )
    {
        if ( !this.eventListenerMap.has(  ) )
        {
            this.eventListenerMap.set( eventName, [] );
        }

        this.eventListenerMap
            .get( eventName )
            .push( listener );
    }

    // ~~

    off(
        eventName,
        listener
        )
    {
        if ( this.eventListenerMap.has( eventName ) )
        {
            let eventListenerMap = this.eventListenerMap.get( eventName );
            let eventListenerIndex = eventListenerMap.indexOf( listener );
            let eventListenerExists = eventListenerIndex !== -1;

            if ( eventListenerExists )
            {
                eventListenerMap.splice( eventListenerIndex, 1 );
            }
        }
    }

    // ~~

    emit(
        eventName,
        ...args
        )
    {
        if ( this.eventListenerMap.has( eventName ) )
        {
            let eventListenerMap = this.eventListenerMap.get( eventName );

            for ( let eventListener of eventListenerMap )
            {
                eventListener( ...args );
            }
        }
    }
}
