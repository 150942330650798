// -- IMPORTS

import { writable } from 'svelte/store';

// -- STATEMENTS

export let selectedPlan = writable( null );
export let selectedFee = writable( 'monthlyFee' );
export let selectedExtraService = writable( [] );
export let selectedCustomizedFromBamhubService = writable( [] );
export let selectedServiceOfferedByValBusin = writable( [] );
export let selectedServiceOfferedViaBam = writable( [] );

selectedPlan.subscribe(
    ( plan ) =>
    {
        selectedExtraService.set( [] );
        selectedCustomizedFromBamhubService.set( [] );
        selectedServiceOfferedByValBusin.set( [] );
        selectedServiceOfferedViaBam.set( [] );

        return plan;
    }
    );

selectedFee.subscribe(
    ( fee ) =>
    {
        selectedExtraService.set( [] );

        return fee;
    }
    );
