// -- VARIABLES

export let imageFilePathPrefix = 'https://eguzzwfhunynwpfcdxdz.supabase.co/storage/v1/object/public/global';
export let imageFilePathSuffix = '';

// -- FUNCTIONS

export function getFilePathType(
    filePath
    )
{
    let fileType = filePath.split( '.' ).pop();
    switch ( fileType.toLowerCase() )
    {
        case 'jpg':
        case 'JPG':
        case 'jpeg':
        case 'JPEG':
            return 'jpg';
        case 'png':
        case 'PNG':
            return 'png';
        case 'svg':
        case 'SVG':
            return 'svg';
        case 'mp4':
        case 'MP$':
            return 'mp4';
        case 'avif':
        case 'AVIF':
            return 'avif';
        default:
            return 'webp';
    }
}

// ~~

export function getFilePathWithLowerCaseExtension(
    filePathWithExtension
    )
{
    let filePathPartArray = filePathWithExtension.split( '.' );
    let fileExtension = filePathPartArray.pop();
    let fileWithPath = filePathPartArray.join('.');

    if ( fileExtension === 'jpg'
        || fileExtension === 'jpeg'
    )
    {
        return fileWithPath + ".jpg";
    }
    else if ( fileExtension === 'MP$' )
    {
        return fileWithPath + ".mp4";
    }
    else
    {
        return filePathWithExtension
    }
}

// ~~

function stringContainsElementOfArray(
    string,
    array
    )
{
    let elementsOnString = array.some( 
                                element => string.includes( element )
                                );

    return elementsOnString;
}

// ~~

export function getImagePath(
    filePath,
    fileSize = '1920',
    isAvif = true
    )
{
    if( filePath )
    {
        let encodedImagePath = filePath.replace( /\//g, '/' );
        encodedImagePath = getFilePathWithLowerCaseExtension( encodedImagePath );
        let originalFileExtension = getFilePathType( filePath );
        let newFileExtension = isAvif ? 'avif' : getFilePathType( filePath );
        let ImageSizes = [ '.640', '.1280', '.1920', '.3840' ] 
        
        let encodedImagePathHasSize = stringContainsElementOfArray( encodedImagePath, ImageSizes );

        if ( encodedImagePathHasSize && fileSize )
        {
            encodedImagePath = encodedImagePath.replace( /\.(640|1280|1920|3840)/,  '.' + fileSize );
            fileSize = '';
        }
        
        if ( filePath.includes( 'global' ) )
        {
            imageFilePathPrefix = 'https://eguzzwfhunynwpfcdxdz.supabase.co/storage/v1/object/public'    
        }
        else
        {
            imageFilePathPrefix = 'https://eguzzwfhunynwpfcdxdz.supabase.co/storage/v1/object/public/global'  
        }

        if ( originalFileExtension === 'webp' )
        {
            return imageFilePathPrefix + encodedImagePath + imageFilePathSuffix;
        }
        else if ( originalFileExtension === 'svg' )
        {
            return imageFilePathPrefix + encodedImagePath + imageFilePathSuffix;
        }
        else if ( fileSize )
        {            
            return imageFilePathPrefix + encodedImagePath + '.' + fileSize + '.' + newFileExtension + imageFilePathSuffix;
        }
        else
        {
            return imageFilePathPrefix + encodedImagePath + imageFilePathSuffix;
        }
    }
    else
    {
        return '';
    }
}
