<script>
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import Seo from '$lib/component/element/Seo.svelte';
    import { websiteUrl } from '$lib/base.js';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { textStore } from '$lib/store/textStore';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import { fetchData } from '../base';
    import { pageStore } from '../store/pageDataStore';
    import { onMount } from 'svelte';
    import { languageArrayStore } from '../store/languageArrayStore';
    import FullLoading from '../component/Layout/FullLoading.svelte';
  import urlStore from '../store/urlStore';

    // -- VARIABLES

    let isLoading = true;
    let metaTitle = '';
    let metaDescription = '';
    let metaKeywords = '';
    let imagePath = '';
    let url = '';

    let networkingPage = $pageStore.page.networkingPage;
    let networkingBlockArray = $pageStore.block.networkingSection;

    // -- FUNCTIONS

    async function loadData()
    {
        try
        {
            isLoading = true

            let [networkingPageData, networkingBlockArrayData] = await Promise.all(
                [
                    fetchData(
                        '/api/page/get-by-route',
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    route: '/networking'
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    ),
                    fetchData(
                        '/api/block/get-by-page-id',
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    pageId: 'GBrkJ0WRcoG4BbYo5k2FbA'
                                }
                                ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    )
                ]
            )

            $pageStore.page.networkingPage = networkingPageData.page;
            $pageStore.block.networkingSection = networkingBlockArrayData.blockArray
            networkingBlockArray = networkingBlockArrayData.blockArray;
            networkingPage = networkingPageData.page;
            metaTitle = networkingPageData.page.metaTitle || networkingPageData.page.title;
            metaDescription = networkingPageData.page.metaDescription || networkingPageData.page.subTitle;
            metaKeywords = networkingPageData.page.metaKeywords;
            imagePath = networkingPageData.page.imagePath;
            url = websiteUrl;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            if( networkingPage === undefined || networkingBlockArray === undefined ) {
                loadData()
            }
            else
            {
                isLoading = false;
            }
        }
    );

    $: if ( $urlStore.pathname.includes( 'networking' ) )
        {
            document.title = 'Networking';
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .networking-section
    {
        padding: 5rem 1.5rem;

        gap: 5rem;

        +media( desktop )
        {
            max-width: 77vw;
        }
    }

    .networking-heading
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        align-self: stretch;
    }

    .networking-title
    {
        line-height: 2.5rem;
        font-size: 2rem;
        letter-spacing: 0.16rem;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            font-weight: 250;
            letter-spacing: 0.48rem;
            text-align: center;
        }
    }

    .networking-infinite-scroll-list
    {
        width: 100%;
        width: max-content;

        display: flex;
        flex-wrap: nowrap;
        gap: 3rem;
        align-items: center;
    }

    .networking-infinite-scroll-list.is-animated
    {
        animation: infinite-scroll 200s forwards linear infinite;
    }

    .networking-infinite-scroll-item
    {
        padding: 1.25rem;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        justify-content: center;
        align-items: center;
    }

    .what-we-offer-container
    {
    }

    @keyframes infinite-scroll
    {
        to
        {
            transform: translate( calc( -50% - 0.5rem ) );
        }
    }

    .what-we-offer-container
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        align-self: stretch;

        +media( desktop )
        {
            gap: 5rem;
        }
    }

    .what-we-offer-card-list
    {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        align-items: center;
        align-self: stretch;

        +media( desktop )
        {
            gap: 4rem;
        }
    }

    .what-we-offer-card-item
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            flex-direction: row;
            gap: 5rem;
        }
    }

    .what-we-offer-card-item.reverse
    {
        +media( desktop )
        {
            flex-direction: row-reverse;
        }
    }

    .what-we-offer-card-item-content
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            display: flex;
            flex: 1 0 0;
            gap: 2rem;
            justify-content: center;
        }
    }

    .what-we-offer-card-item-title
    {
        line-height: 2rem;
        font-size: 1.4375rem;
        font-weight: 500;
        letter-spacing: 0.08625rem;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 2.5rem;
            font-size: 2rem;
            letter-spacing: 0.16rem;
        }
    }

    .what-we-offer-image
    {
        height: 18.75rem;
        aspect-ratio: 1;

        align-self: stretch;
        object-fit: cover;

        +media( desktop )
        {
            height: 40rem;
            width: 44.375rem;
            aspect-ratio: 3 / 4;
        }
    }

    .networking-infinite-scroll-image
    {
        height: 3.5rem;

        object-fit: cover;
    }

    .networking-infinite-scroll-list.is-animated.slow
    {
        animation-duration: 500s;
    }
</style>

{ #if isLoading }
    <FullLoading />
{ :else }
    <Seo
        metaTitle={ metaTitle }
        metaDescription={ metaDescription }
        metaKeywords={ metaKeywords }
        url={ url }
        imagePath={ imagePath }
        languageData={ $languageArrayStore }
        path=''
    />

    <section class="networking-section">
        <div class="networking-heading" class:is-admin-enabled={ $enabledAdminView }>
            <AdminEditButton
                hash="networking"
            />
            { #if networkingPage.title }
                <p class="networking-title color-light-gold">
                    <AnimatedText text={ getLocalizedText( networkingPage.title, $languageTagStore ) }/>
                </p>
            { /if }

            { #if networkingPage.subTitle }
                <p class="description-text description-text-align-center-desktop color-light-gray">
                    <AnimatedText text={ getLocalizedText( networkingPage.subTitle, $languageTagStore ) }/>
                </p>
            { /if }
        </div>

        <div class="what-we-offer-container">
            <p class="networking-title color-light-gold" class:is-admin-enabled={ $enabledAdminView }>
                <AdminEditButton
                    type="text"
                    slug="what-we-offer-title-label"
                    inset="-2.5rem -1.5rem auto auto"
                />
                <AnimatedText text={ getLocalizedText( $textStore[ 'what-we-offer-title-label' ] || '', $languageTagStore ) }/>
            </p>

            <div class="what-we-offer-card-list">
                { #each networkingBlockArray as { id, imagePath, title, text, imageSide } }
                    <AnimatedContainer>
                        <div class="what-we-offer-card-item { imageSide === 'right' ? 'reverse' : '' }" class:is-admin-enabled={ $enabledAdminView }>
                            <AdminEditButton
                                type="block"
                                id={ id }
                            />
                            { #if imagePath }
                                <img class="what-we-offer-image" loading="lazy" src={ getImagePath( imagePath ) } alt={ getLocalizedText( title || '', $languageTagStore ) }>
                            { /if }

                            <div class="what-we-offer-card-item-content">
                                <p class="what-we-offer-card-item-title color-light-gold">{ getLocalizedText( title || '', $languageTagStore ) }</p>

                                <p class="description-text">{ getLocalizedText( text || '', $languageTagStore ) }</p>
                            </div>
                        </div>
                    </AnimatedContainer>
                { /each }
            </div>
        </div>
    </section>
{ /if }
