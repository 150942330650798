<script>
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import { websiteUrl } from '$lib/base.js';
    import { enabledAdminView } from '$lib/store/adminStore';
    import { getLocalizedText } from 'senselogic-gist';
    import { navigate } from 'svelte-routing';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { textStore } from '$lib/store/textStore';
    import Seo from '$lib/component/element/Seo.svelte';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import Button from '$lib/component/element/Button.svelte';
    import { pageStore } from '../store/pageDataStore';
    import { fetchData } from '../base';
    import { onMount } from 'svelte';
    import { languageArrayStore } from '../store/languageArrayStore';
    import FullLoading from '../component/Layout/FullLoading.svelte';
  import urlStore from '../store/urlStore';

    // -- VARIABLES

    let isLoading = true;
    let metaTitle = '';
    let metaDescription = '';
    let metaKeywords = '';
    let imagePath = '';
    let url = '';

    let hubsPage = $pageStore.page.hubsPage;
    let hubArray = $pageStore.block.hubsSection;

    // -- FUNCTIONS

    async function loadData()
    {
        try
        {
            isLoading = true

            let hubsPageData = await 
                    fetchData(
                        '/api/get-hubs',
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    );

            $pageStore.page.hubsPage = hubsPageData.page;
            $pageStore.block.hubsSection = hubsPageData.hubArray;
            hubArray = hubsPageData.hubArray;
            hubsPage = hubsPageData.page;

            metaTitle = hubsPageData.page.metaTitle ?? hubsPageData.page.title;
            metaDescription = hubsPageData.page.metaDescription ?? hubsPageData.page.subTitle ?? "Meet all our hubs, and the amazing benefits of each one";
            metaKeywords = hubsPageData.page.metaKeywords;
            imagePath = hubsPageData.page.imagePath;
            url = websiteUrl;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            if( hubsPage === undefined || hubArray === undefined ) {
                loadData()
            }
            else
            {
                isLoading = false;
            }
        }
    );

    $: if ( $urlStore.pathname.includes( 'hubs' ) )
        {
            document.title = 'Hubs';
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .hubs-section
    {
        padding: 5rem 1.5rem;

        gap: 4rem;

        +media( desktop )
        {
            max-width: 77vw;
            padding: 5rem 0rem 7.5rem 0rem;
        }
    }

    .hubs-title
    {
        line-height: 2.5rem;
        font-size: 2rem;
        letter-spacing: 0.16rem;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            font-weight: 250;
            letter-spacing: 0.48rem;
            text-align: center;
        }
    }

    .hubs-list
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 4rem;
        align-items: center;
        align-self: stretch;

        +media( tablet )
        {
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 2rem;
            align-items: center;
            column-gap: 2rem;
        }

        +media( desktop )
        {
            grid-template-columns: 1fr 1fr 1fr;
            row-gap: 6rem;
            justify-content: space-between;
        }
    }

    .hubs-item
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            flex: 1 0 0;
        }
    }

    .hubs-item-content
    {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: flex-start;
        align-self: stretch;
    }

    .hubs-item-content-title
    {
        align-self: stretch;

        line-height: 2rem;
        font-size: 1.4375rem;
        font-weight: 500;
        letter-spacing: 0.08625rem;
        text-transform: uppercase;
    }

    .hubs-item:hover .hubs-item-image
    {
        scale: 1.05;
    }

    .hubs-item-image
    {
        height: 18.75rem;
        aspect-ratio: 1;

        align-self: stretch;
        object-fit: cover;

        transition: scale .4s ease-in-out;

        +media( desktop )
        {
            height: 28.75rem;
        }
    }
</style>

{ #if isLoading }
    <FullLoading />
{ :else }
    <Seo
        metaTitle={ metaTitle }
        metaDescription={ metaDescription }
        metaKeywords={ metaKeywords }
        url={ url }
        imagePath={ imagePath }
        languageData={ $languageArrayStore }
        path=''
    />

    <section class="hubs-section">
        <p class="hubs-title color-light-gold" class:is-admin-enabled={ $enabledAdminView }>
            <AnimatedText text={ getLocalizedText( hubsPage.title || '', $languageTagStore ) }/>

            <AdminEditButton
                hash="hubs"
            />
        </p>

        <div class="hubs-list">
            { #each hubArray as { id, name, imagePath, slug }, hubItemIndex }
                <AnimatedContainer style="width: 100%;" delay="{ hubItemIndex * 100 }ms">
                    <div class="hubs-item" class:is-admin-enabled={ $enabledAdminView }>
                        { #if imagePath }
                            <img
                                class="hubs-item-image"
                                loading="lazy"
                                src={ getImagePath( imagePath ) }
                                alt={ getLocalizedText( name || '', $languageTagStore ) }>
                        { /if }

                        <div class="hubs-item-content">
                            { #if name }
                                <p
                                    class="hubs-item-content-title color-light-grey"
                                >
                                    { getLocalizedText( name || '', $languageTagStore ) }
                                </p>
                            { /if }

                            { #if slug }
                                <Button fullWidth on:click={ () => navigate( `/${ $languageTagStore }/hub/${ slug }` ) }>
                                    { getLocalizedText( $textStore[ 'hubs-see-more-button' ] || '', $languageTagStore ) }
                                </Button>
                            { /if }
                        </div>

                        <AdminEditButton
                            type="hub"
                            id={ id }
                        />
                    </div>
                </AnimatedContainer>
            { /each }
        </div>
    </section>
{ /if }
