<script>
    // -- IMPORTS

    import DropdownNavigationLink from "../element/DropdownNavigationLink.svelte";
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { languageArrayStore } from "../../store/languageArrayStore";
    import { languageByCodeMapStore } from "../../store/languageByCodeMapStore";

    // -- CONSTANTS

    let optionArray = populateLanguageOptions();

    // -- VARIABLES

    export let isMobileNavigationOpen;
    let languageName = $languageByCodeMapStore[ $languageTagStore ].code;
    let languageIconPath = $languageByCodeMapStore[ $languageTagStore ].iconPath;

    // -- FUNCTIONS

    function populateLanguageOptions(
        )
    {
        let optionArray = [];

        for ( let language of $languageArrayStore )
        {
            optionArray.push(
                {
                    languageName: language.name,
                    languageCode: language.code,
                    languageIconPath: language.iconPath
                }
            );
        }

        return optionArray;
    }
</script>

<DropdownNavigationLink
    bind:isMobileNavigationOpen
    languageName={ languageName }
    languageIconPath={ languageIconPath }
    optionArray={ optionArray }
/>
