// -- IMPORTS

import './app.styl';
import './app.css'
import App from './App.svelte';

// -- STATEMENTS

let app =
    new App(
        {
            target: document.getElementById( 'app' )
        }
        );

export default app
