<script>
    // -- IMPORTS

    import { inview } from 'svelte-inview';

    // -- VARIABLES

    let inView = false;

    // -- CONSTANTS

    export let threshold = 0.25;
    export let slideDirection = 'bottom';
    export let delay = '0ms';
    export let style = null;
</script>

<style lang="stylus">
    // -- ELEMENTS

    div
    {
        opacity: 0;
    }

    // -- CLASSES

    .slide-in-bottom
    {
        animation: slide-in-bottom 1.8s ease both;
    }

    .slide-in-left
    {
        animation: slide-in-left 1.8s ease both;
    }

    .slide-in-right
    {
        animation: slide-in-right 1.8s ease both;
    }

    // -- ANIMATIONS

    @keyframes slide-in-bottom
    {
        0%
        {
            transform: translateY( 150px );

            opacity: 0;
        }

        100%
        {
            transform: translateY( 0 );

            opacity: 1;
        }
    }

    @keyframes slide-in-left
    {
        0%
        {
            transform: translateX( -150px );

            opacity: 0;
        }

        100%
        {
            transform: translateX( 0 );

            opacity: 1;
        }
    }

    @keyframes slide-in-right
    {
        0%
        {
            transform: translateX( 150px );

            opacity: 0;
        }

        100%
        {
            transform: translateX( 0 );

            opacity: 1;
        }
    }
</style>

<div
    use:inview=
    {
        {
            threshold: threshold,
            unobserveOnEnter: true
        }
    }
    on:inview_enter={ () => inView = true }
    class="{ inView ? `slide-in-${ slideDirection }` : '' }"
    style="animation-delay: { delay }; { style }"
>
    <slot/>
</div>
