<script>
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import { onMount } from 'svelte';
    import PageHeading from '../component/header/PageHeading.svelte';
    import { fetchData, isObjectEmpty, websiteUrl } from '../base';
    import Seo from '../component/element/Seo.svelte';
    import { languageArrayStore } from '../store/languageArrayStore';
    import { getLocalizedText } from 'senselogic-gist';
    import { pageStore } from '../store/pageDataStore';
    import FullLoading from '../component/Layout/FullLoading.svelte';
    import urlStore from '../store/urlStore';

    // -- VARIABLES

    let isLoading = true;
    let homePage = $pageStore.page.homePage;
    let name = '';
    let metaTitle = '';
    let metaDescription = '';
    let metaKeywords = '';
    let imagePath = '';
    let url = '';

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true

            let data =
                await fetchData(
                    '/api/page/get-by-route',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                route: '/'
                            }
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            $pageStore.page.homePage = data.page;
            homePage = data.page;
            name = data.page.slug;
            metaTitle = data.page.title;
            metaDescription = data.page.subTitle;
            metaKeywords = data.page.metaKeywords;
            imagePath = data.page.imagePath;
            url = websiteUrl;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // -- STATEMENTS

    onMount(
        async () =>
        {
            if ( homePage === undefined )
            {
                await loadData()
            }
            else
            {
                isLoading = false;
            }
        }
    );

    $: if ( $urlStore.pathname === '/en' )
        {
            document.title = 'Home';
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .home-image-container
    {
        height: 32.5rem;
        width: 100%;

        +media( smaller-32em )
        {
            background-position: center right 30% !important;
        }
    }
</style>

<svelte:head>
    { #if !isObjectEmpty( name ) }
        <title>{ getLocalizedText( name ) }</title>
    { /if }
</svelte:head>

{ #if isLoading }
    <FullLoading />
{ :else }
    <Seo
        metaTitle={ metaTitle }
        metaDescription={ metaDescription }
        metaKeywords={ metaKeywords }
        url={ url }
        imagePath={ imagePath }
        languageData={ $languageArrayStore }
        path=""
    />

    <PageHeading
        title={ homePage.title }
        subtitle={ homePage.subTitle }
    />

    <div
        class="home-image-container"
        style="
                background-image: url('{ getImagePath( homePage.imagePath ) }');
                background-position: { homePage.imageHorizontalPosition } { homePage.imageVerticalPosition };
                background-size: { homePage.imageFit };
                height: { homePage.imageHeight };
               "
    />

    { #await import( '$lib/component/element/AdminEditButton.svelte' ) then { default : AdminEditButton } }
        <AdminEditButton
            inset="10rem 5rem auto auto"
            hash="home"
        />
    { /await }

    { #await import( '../component/homePage/WhatsBamhubSection.svelte' ) then { default : WhatsBamhubSection } }
        <WhatsBamhubSection />
    { /await }

    { #await import( '../component/homePage/WhyBamhubSection.svelte' ) then { default : WhyBamhubSection } }
        <WhyBamhubSection />
    { /await }

    { #await import( '../component/element/MembershipPlanTable.svelte' ) then { default : MembershipPlanTable } }
        <MembershipPlanTable redirect />
    { /await }
{ /if }
