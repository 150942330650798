<script>
    // -- IMPORTS

    import { enabledAdminView } from '$lib/store/adminStore';
    import IconButton from './IconButton.svelte';
    import { languageTagStore } from '$lib/store/languageTagStore';

    // -- VARIABLES

    export let inset = '-0.5rem -1rem auto auto';
    export let type = null;
    export let id = null;
    export let slug = null;
    export let hash = null;

    let route = window.origin.includes('localhost') ? 'http://localhost:5173' : '';

    let path;

    if ( slug )
    {
        path = slug;
    }
    else
    {
        path = id;
    }
</script>

<style lang="stylus">
    // -- ELEMENTS

    a
    {
        z-index: 1;
    }

    // -- CLASSES

    .admin-edit-button
    {
        position: absolute;

        cursor: pointer;
    }
</style>

{ #if $enabledAdminView }
    <a
        href="{ route }/{ $languageTagStore }/admin{ type ? `/${type}` : `#${hash}` }{ path ? `/edit/${ path }` : '' }"
        target="_blank" class="admin-edit-button"
        style="inset: { inset };"
    >
        <IconButton color="#ADA58C">
            <div class="dark-grey-edit-icon size-150"/>
        </IconButton>
    </a>
{ /if }
